@font-face {
  font-family: FairaSans;
  src: url(../fonts/FiraSans-Regular.woff) format("woff"), url(../fonts/FiraSans-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: FairaSans;
  src: url(../fonts/FiraSans-Light.woff) format("woff"), url(../fonts/FiraSans-Light.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: FairaSans;
  src: url(../fonts/FiraSans-Bold.woff) format("woff"), url(../fonts/FiraSans-Bold.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

/*************************/
/**** TYPO ****/
/*************************/
.font-normal {
  font-weight: normal; }

.font-semi-bold {
  font-weight: 500; }

.font-black {
  font-weight: 900; }

.font-size1 {
  font-size: 0.9rem; }

.fontsize50 {
  font-size: 50%; }

.fontsize90 {
  font-size: 90%; }

.fontsize110 {
  font-size: 110%; }

.fontsize120 {
  font-size: 120%; }

.text-center {
  text-align: center; }

.font-spacing {
  letter-spacing: 0.4rem; }

.page-title {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 4px; }

.display-5 {
  font-size: 2rem;
  line-height: 1.2; }

.display-6 {
  font-size: 1.5rem;
  line-height: 1.2; }

.display-7 {
  font-size: 0.9rem;
  line-height: 1.2; }

.color1 {
  color: #6B7E9B; }

.color2 {
  color: #F2B52D; }

.bg-color2 {
  background-color: #F2B52D; }

.bg-color1 {
  background-color: #6B7E9B; }

.bg-color3 {
  background-color: #707070; }

.bg-color4 {
  background-color: #B5CC67; }

.bg-color5 {
  background-color: #EBEDF1; }

.bg-color6 {
  background-color: #571c84; }

.border-color1 {
  border: solid 1px #B1B9C5; }

/* Bootstrap 4
.col- (extra small devices - screen width less than 576px)
.col-sm- (small devices - screen width equal to or greater than 576px)
.col-md- (medium devices - screen width equal to or greater than 768px)
.col-lg- (large devices - screen width equal to or greater than 992px)
.col-xl- (xlarge devices - screen width equal to or greater than 1200px)
*/
/* A utiliser si site pas en full width */
.container {
  max-width: 1140px; }

.btn-custom {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  font-size: 1.8rem;
  background: #F2B52D;
  color: #fff !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  padding: 1rem 1.5rem; }

.btn.focus, .btn:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show > .btn.dropdown-toggle:focus {
  box-shadow: none; }

.btn-primary {
  background: #F2B52D;
  color: white !important;
  border: none;
  border-color: #999999;
  border-radius: 0;
  font-size: 1.8rem; }

.btn-primary:hover,
.btn-primary:focus {
  background: #B5CC67;
  border-color: #999999; }

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background: #365249;
  border-color: #365249; }

.btn-primary.disabled, .btn-primary:disabled {
  background: #999999;
  border-color: #999999; }

.btn-secondary {
  background: #999999;
  color: #FFFFFF;
  border-color: #999999; }

.btn-secondary:hover {
  background: #999999;
  border-color: #999999; }

.btn-outline-primary {
  border-color: #999999;
  color: #5D8158; }

.btn-outline-primary:hover {
  background-color: #999999;
  border-color: #999999;
  color: #FFFFFF; }

.nav-tabs .nav-link {
  color: #6B7E9B !important;
  letter-spacing: 0.8px; }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border: none;
  border-bottom: solid 2px #F2B52D; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.rassurance,
.text,
.t1, .t2, .t3,
.title,
.desc,
.titre,
.stitre,
.sstitre,
.news_content {
  word-wrap: normal;
  hyphens: none;
  line-height: 1;
  letter-spacing: 0.8px; }

h1,
.h1,
.titre {
  display: inline-block;
  position: relative;
  color: #6B7E9B;
  font-size: 4.5rem;
  font-weight: 400;
  margin-top: 1rem;
  line-height: 1;
  padding-bottom: 2rem; }
  h1::after,
  .h1::after,
  .titre::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1rem;
    display: inline-block;
    height: .1rem;
    width: calc(100% + 5rem);
    background-color: #6B7E9B; }

h2,
.h2,
.stitre {
  font-size: 4.0rem;
  color: #6B7E9B;
  margin: 0.6rem 0;
  font-weight: 700;
  line-height: 1;
  text-align: left; }

h3,
.h3,
.sstitre {
  font-size: 3.6rem;
  color: #6B7E9B;
  margin: 0.6rem 0;
  font-weight: 700;
  line-height: 1; }

h4,
.h4,
.ssstitre {
  font-size: 2.4rem;
  color: #6B7E9B;
  font-weight: 700;
  margin: 0.6rem 0;
  line-height: 1;
  text-align: left; }

@media (max-width: 991px) {
  body {
    font-size: 1.5rem; }
  .h1,
  .titre,
  h1 {
    font-size: 4rem; }
  .h2,
  .stitre,
  h2 {
    font-size: 3rem; }
  .h3,
  .sstitre,
  h3 {
    font-size: 2rem; }
  .h4,
  .ssstitre,
  h4 {
    font-size: 2rem; } }

/*** Style generique ***/
body,
button,
fieldset,
form,
html,
textarea {
  font-family: "FairaSans", Helvetica, Arial, sans-serif; }

body,
html {
  scroll-behavior: smooth;
  overflow-x: hidden !important; }

body,
body.mce-content-body {
  color: #6B7E9B;
  background-color: #fff; }

html {
  font-size: 62.5%;
  height: 100%; }

body {
  font-family: "FairaSans", Helvetica, Arial, sans-serif;
  font-size: 1.8rem; }

p {
  line-height: 1.3; }

.scroller {
  overflow-x: hidden; }

.overflow-hidden {
  overflow: hidden; }

#grid_builder_content .row {
  margin: 0 auto 2rem auto;
  padding: 2rem;
  overflow: hidden;
  background-color: #fff; }

#body_cms #bloc {
  padding: 2rem 2rem 0; }

#body_cms .content .container .margin_content {
  padding: 0; }

#body_cms .contenu_content,
#cms {
  margin-top: 0; }

#body_cms .content a {
  color: #6B7E9B; }

#body_cms a.bx-next,
#body_cms a.bx-prev {
  color: #fff; }

#body_cms .content a:hover {
  color: #B5CC67; }

a {
  transition: all .3s ease-in; }

.cms a:not(.button),
.mce-content-body a:not(.button),
a {
  color: #6B7E9B;
  text-decoration: none;
  cursor: pointer; }

.cms a:not(.button):focus,
.cms a:not(.button):hover,
.mce-content-body a:not(.button):focus,
.mce-content-body a:not(.button):hover,
a:focus,
a:hover {
  text-decoration: none;
  /*color: #32435D;*/
  color: #B5CC67; }

.link_view_item {
  background: #F2B52D;
  color: #fff;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  font-size: 1.6rem; }
  .link_view_item:hover {
    background: #6B7E9B;
    color: #fff; }

.fixed {
  position: fixed; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.static {
  position: static;
  overflow: hidden; }

.inline {
  display: inline; }

.inlineblock {
  display: inline-block; }

.block {
  display: block; }

.flex {
  display: -ms-flexbox;
  display: flex; }

.hidden,
.masque {
  display: none; }

.floatleft {
  float: left; }

.floatright {
  float: right; }

.floatnone {
  float: none; }

.textleft {
  text-align: left; }

.textright {
  text-align: right; }

.textcenter {
  text-align: center; }

.textjustify {
  text-align: justify; }

.width80 {
  width: 80%; }

.width60 {
  width: 60%; }

.width50 {
  width: 50%; }

.width45 {
  width: 45%; }

.width35 {
  width: 35%; }

.width33 {
  width: 33.33333333%; }

.width25 {
  width: 25%; }

.margin0 {
  margin: 0; }

.marginauto {
  margin: auto; }

.lineh15 {
  line-height: 1.5; }

.lineh25 {
  line-height: 2.5; }

.marginright {
  margin-right: 2rem; }

.marginleft {
  margin-left: 2rem; }

.marginbottom {
  margin-bottom: 2rem; }

.margintop {
  margin-top: 2rem; }

.maxw100 {
  max-width: 10rem;
  margin: .5rem auto;
  min-width: 10rem; }

.maxw150 {
  max-width: 15rem;
  margin: .5rem auto;
  min-width: 15rem; }

.maxw180 {
  max-width: 18rem;
  margin: .5rem auto;
  min-width: 18rem; }

.maxw280 {
  max-width: 28rem;
  margin: .5rem auto;
  min-width: 28rem; }

.padding10 {
  padding: 1rem; }

.padding25 {
  padding: 2.5rem; }

.padding30 {
  padding: 3rem; }

.padding40 {
  padding: 4rem; }

.padding1020 {
  padding: 1rem 2rem; }

.nomarge {
  padding-right: 0;
  padding-left: 0; }

.transition {
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  transition: all .3s ease-in; }

.aheight {
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle; }

.vmiddle {
  vertical-align: middle; }

.vtop {
  vertical-align: top; }

.vbottom {
  vertical-align: bottom; }

.flex_vcenter {
  display: flex;
  align-items: center; }

.mini,
fontsize80 {
  font-size: 80%; }

.fontsize85 {
  font-size: 85%; }

.fontsize90 {
  font-size: 90%; }

.fontsize110 {
  font-size: 110%; }

.big,
.fontsize120 {
  font-size: 120%; }

.bold,
.strong,
strong {
  font-weight: 700; }

.lowercase {
  text-transform: lowercase; }

.uppercase {
  text-transform: uppercase; }

figure {
  margin: auto; }

.mobile,
figcaption {
  display: none; }

.desktop {
  display: block; }

:-ms-input-placeholder,
::-moz-placeholder,
::-webkit-input-placeholder,
::placeholder {
  color: #91a1a1;
  font-style: italic;
  text-transform: none;
  font-weight: 700;
  font-size: 1.3rem; }

.icon,
.text {
  display: inline-block;
  vertical-align: middle; }

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  height: 0; }

.clearfix:after,
.clearfix:before {
  content: '.';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0; }

.clearfix:after,
.line,
.row,
.wrap {
  clear: both; }

.sep {
  margin: 1.5rem 0;
  height: .1rem;
  background-color: #d4d8da; }

.card-color-1 .content {
  background: #fef5e5;
  padding: 3rem; }

.card-color-2 .content {
  background: #e6f4fa;
  padding: 3rem; }

.card-color-3 .content {
  background: #f3fae6;
  padding: 3rem; }

img {
  max-width: 100%; }

.cms img {
  height: auto; }

.box_shadow img {
  border-radius: 0;
  background: #dddddd;
  box-shadow: 5px 5px 10px #aaa, -5px -5px 10px #eaeaea; }

.cms .img_cover * {
  height: 100%; }

.cms .img_cover img {
  object-fit: cover; }

#builder .row {
  color: inherit !important; }

#builder .texte_content ol,
#builder .texte_content ul,
.cms ol,
.cms ul,
.faq_answer ol,
.faq_answer ul,
.mce-content-body ol,
.mce-content-body ul {
  line-height: 2.5rem;
  list-style-type: inherit; }

#builder .texte_content ol li,
#builder .texte_content ul li,
.cms ol li,
.cms ul li,
.faq_answer ol li,
.faq_answer ul li,
.mce-content-body ol li,
.mce-content-body ul li {
  list-style-position: inside;
  margin: .4rem auto .4rem 2rem; }

#builder .texte_content ol,
.cms ol,
.faq_answer ol,
.mce-content-body ol {
  list-style-type: decimal; }

.liste li {
  list-style-type: square;
  list-style-position: inside;
  margin-left: 1rem;
  padding: .2rem 0; }

.cms .table-container {
  width: 100%;
  overflow-y: auto; }

.cms thead {
  background: #6B7E9B;
  color: #fff;
  text-transform: uppercase; }

.cms table {
  display: table;
  border: 0.1rem solid #6B7E9B;
  border-collapse: collapse;
  font-size: 1.4rem;
  background-color: #fff;
  color: #6B7E9B; }

.cms table td,
.cms table th {
  border: 0.1rem solid #6B7E9B;
  padding: .5rem 1rem; }

.cms table th {
  padding: 1rem; }

.cms td {
  display: table-cell; }

.cms p {
  line-height: 1.5; }

.cms h3 {
  margin: 4rem 0 2rem 0;
  font-size: 3rem; }

.cms h2 {
  margin: 4rem 0 2rem 0;
  color: #AAB5C6; }

.entete_accordeon,
.ui-accordion .entete_accordeon {
  background: #209cbf none repeat scroll 0 0;
  border: .1rem solid #209cbf;
  color: #fff;
  font-size: 1.9rem;
  padding: .3rem 1rem;
  border-radius: 0; }

.mce-content-body .stitre.entete_accordeon:after,
.mce-content-body h2.entete_accordeon:after,
.mce-content-body h2:after {
  border-bottom: none;
  margin: auto;
  width: auto;
  display: inline-block; }

#form-builder .liste-content .video_content,
#grid_builder .content.type-video,
#video_home .video_c {
  width: 100%;
  padding-top: 56%;
  height: 0;
  position: relative; }

#grid_builder .content.type-video .abs_ratio,
#video_home .absolute_c {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

#form-builder .liste-content .video_content iframe,
#grid_builder .content.type-video iframe,
#video_home .absolute_c iframe {
  max-width: 100%;
  min-width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.slick-dots li button::before {
  background-color: #6B7E9B; }

.current_article .main_image {
  padding-left: 0; }
  .current_article .main_image img {
    height: 50rem;
    object-fit: cover;
    width: 100%;
    box-shadow: 6px 6px 6px 0 #cccccc; }

.current_article #lst_vignette {
  margin: .5rem -.5rem 1rem; }
  .current_article #lst_vignette .slick-slide > div {
    margin: .2rem .6rem; }
    .current_article #lst_vignette .slick-slide > div img {
      object-fit: cover;
      cursor: pointer; }

.current_article .slick-track {
  margin: 0; }

#chemin {
  display: flex;
  align-items: center;
  color: #6B7E9B;
  background-color: #f4f4f4;
  overflow: hidden;
  min-height: 4rem;
  margin-bottom: 2rem;
  font-size: 1.2rem; }

#chemin p {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.5rem; }

.breedcrumb_sep {
  font-size: 2rem;
  margin-left: 1rem;
  margin-right: 1rem; }

#logo {
  position: relative;
  margin-bottom: -30px;
  text-align: center;
  z-index: 4; }

#menu_header_top {
  font-weight: 300; }
  #menu_header_top a {
    color: white; }
  #menu_header_top a:hover {
    color: #F2B52D; }

#header .logo_header_top {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.reseaux a {
  color: black;
  font-size: 1.8rem; }

.reseaux ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .reseaux ul li {
    list-style-type: none; }

/* Menu principal */
#primary_nav_wrap ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0; }
  #primary_nav_wrap ul a,
  #primary_nav_wrap ul .first_level_element {
    font-size: 1.8rem;
    font-weight: bold;
    transition: all .2s ease-in;
    color: #6B7E9B; }
    #primary_nav_wrap ul a:hover,
    #primary_nav_wrap ul .first_level_element:hover {
      color: #F2B52D; }
  #primary_nav_wrap ul .dropdown {
    position: inherit; }

#primary_nav_wrap ul.first_level {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  transition: all .3s ease-in; }
  #primary_nav_wrap ul.first_level > li {
    display: flex;
    align-items: center;
    height: 50%;
    cursor: pointer;
    margin-right: 2em;
    padding-right: 4rem;
    border-right: solid 1px #6B7E9B; }
    #primary_nav_wrap ul.first_level > li:hover > a,
    #primary_nav_wrap ul.first_level > li.current > a,
    #primary_nav_wrap ul.first_level > li:hover .first_level_element,
    #primary_nav_wrap ul.first_level > li.current .first_level_element {
      color: #F2B52D; }
  #primary_nav_wrap ul.first_level > li.dropdown:hover > .second_level {
    opacity: 1;
    visibility: visible; }

#primary_nav_wrap .second_level {
  position: absolute;
  top: 16.3rem;
  right: 0;
  left: 0;
  /*background-color: #AFB9C9;*/
  background-color: #6B7E9B;
  cursor: initial;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
  z-index: 3; }
  #primary_nav_wrap .second_level .icon {
    font-size: 1rem;
    padding-right: 1rem;
    color: #F2B52D; }
  #primary_nav_wrap .second_level .title_smenu {
    font-size: 3.5rem;
    font-weight: 400;
    color: #fff;
    height: 16.4rem;
    text-align: center;
    padding-top: 4rem; }
    #primary_nav_wrap .second_level .title_smenu::after {
      content: '';
      position: absolute;
      top: 2.6rem;
      right: 0;
      display: inline-block;
      width: .1rem;
      height: 11.2rem;
      background-color: #fff; }
  #primary_nav_wrap .second_level ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-height: 140px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 5rem; }
  #primary_nav_wrap .second_level a {
    color: #fff; }

/* Sticky */
#header {
  background: #fff;
  /*height: 165px;*/
  transition: all .3s ease-in; }

#logo img {
  transition: all .3s ease-in; }

#header.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 5;
  height: 100px;
  box-shadow: 0 0.2rem 2rem 0 rgba(0, 0, 0, 0.5); }
  #header.sticky #logo img {
    transition: all .3s ease-in;
    width: 90px; }
  #header.sticky .link_logo::after {
    bottom: -22px;
    right: 31px;
    font-size: 15px; }
  #header.sticky #primary_nav_wrap ul.first_level {
    height: 35px;
    margin-top: 10px;
    transition: all .3s ease-in; }
  #header.sticky #primary_nav_wrap .second_level {
    top: 10rem; }

.sticky + .slider_container {
  padding-top: 50px; }

@media (max-width: 1199px) {
  #header .header_top.sticky {
    padding-left: 3rem;
    padding-right: 3rem; }
  #primary_nav_wrap .second_level .title_smenu {
    font-size: 3rem; } }

@media (max-width: 991px) {
  #logo {
    width: 25rem;
    margin: auto; }
  #menu_launcher {
    top: -4rem; }
  .header_top {
    padding-bottom: 1rem; }
  #primary_nav_wrap {
    width: auto; }
    #primary_nav_wrap ul.first_level {
      display: none; }
  #header .header_top.sticky {
    padding-top: 0;
    min-height: 6rem; }
    #header .header_top.sticky #logo {
      width: 12rem;
      margin-top: .6rem; }
    #header .header_top.sticky .infos_contact_container {
      margin-top: 1rem; }
    #header .header_top.sticky + .header_bribe_container {
      padding-top: 7.5rem; } }

@media (max-width: 767px) {
  #logo {
    width: 20rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  #menu_launcher {
    top: 7rem; }
  #header.sticky #menu_launcher {
    top: 3rem; }
  body .scroller #header .header_bribe_container {
    height: calc(100% - 7.5rem); }
    body .scroller #header .header_bribe_container .header_bribe {
      margin: auto;
      font-size: 1.7rem;
      text-align: center; }
      body .scroller #header .header_bribe_container .header_bribe h2 {
        font-size: 3rem; }
  #header .header_top.sticky + .header_bribe_container {
    padding-top: 12rem; } }

@media (max-width: 575px) {
  #header.sticky {
    height: 50px;
    transition: all .3s ease-in; }
  #menu_header_top a {
    font-size: 1rem; }
  #menu_launcher {
    top: 2rem; }
  #header.sticky #menu_launcher {
    top: 1rem; }
  #logo {
    width: 12rem;
    margin: auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    margin-bottom: -32px; }
  body .scroller #header .header_bribe_container .header_bribe h2 {
    font-size: 2rem; }
  body .scroller #header .header_bribe_container .header_bribe p {
    display: none; } }

#rassurances .icon {
  background: #e7e7e7;
  width: 155px;
  height: 155px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #6B7E9B;
  font-size: 9rem;
  margin: auto;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center; }

#rassurances .rass_title {
  text-transform: uppercase;
  color: #6B7E9B;
  font-size: 1.8rem;
  font-weight: 700; }

#rassurances .rass_title::after {
  content: "";
  display: block;
  height: 3px;
  background-color: #6B7E9B;
  width: 30%;
  margin: auto; }

#footer {
  background: #f2f1ed;
  padding: 5rem 0 2rem 0;
  line-height: 15px;
  margin-top: 5rem; }
  #footer .main_contact .icon-localisation {
    margin-left: -6px;
    margin-right: -4px; }
  #footer .block-adresse {
    height: 100%; }
    #footer .block-adresse .icon {
      margin-top: 1rem;
      vertical-align: bottom;
      margin-right: .5rem; }

.menu_footer2 {
  padding: 1rem;
  text-align: center;
  font-size: 1.2rem;
  color: #fff; }
  .menu_footer2 .main_menu2 {
    position: relative;
    padding: 0;
    margin: auto; }
    .menu_footer2 .main_menu2 li {
      margin: 0;
      list-style-type: none;
      display: inline-block;
      padding: 0 1rem 0 0; }
  .menu_footer2 a {
    color: #fff; }

.menu_footer2 ul.sub_menu li {
  border-right: solid 1px #fff;
  padding-left: 1rem; }

.menu_footer2 ul.sub_menu li:last-child {
  border: 0; }

/* Signature Kyxar */
.kyxar {
  position: absolute;
  z-index: 9;
  white-space: nowrap;
  right: 1rem;
  margin-top: -3rem;
  padding: 0;
  line-height: 2.5rem; }

.kyxar a {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  letter-spacing: 1px;
  position: relative; }

.kyxar a:hover {
  color: #6B7E9B; }

.kyxar a .none {
  position: absolute;
  bottom: 3.8rem;
  right: 2.8rem;
  height: 0;
  overflow: hidden;
  color: #707070; }

.kyxar a:hover .none {
  overflow: visible; }

.kyxar a .none span {
  display: inline-block;
  background-color: #fff;
  border-radius: .3rem;
  padding: .5rem 1rem;
  line-height: 1;
  font-weight: 300;
  font-size: 1rem; }

@media (max-width: 767px) {
  .footer_content {
    flex-direction: column;
    padding-top: 2.5rem;
    padding-bottom: 1.5rem; }
    .footer_content .footer_infos {
      margin-bottom: 3rem; }
      .footer_content .footer_infos .footer_infos_content {
        height: auto; }
    .footer_content .menu_footer {
      text-align: center; }
      .footer_content .menu_footer::before {
        content: "";
        display: block;
        height: .1rem;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
        background-color: #F2B52D; } }

@media (max-width: 767px) {
  #footer {
    padding-bottom: 4rem; } }

#map {
  height: 100%;
  z-index: 1; }
  #map .leaflet-popup p {
    margin: 10px 0;
    text-align: center; }
  #map .leaflet-popup a {
    font-weight: 700; }

#gotop {
  position: fixed;
  bottom: 10rem;
  right: 4rem;
  border-radius: 50%;
  color: #fff;
  background-color: #B5CC67;
  opacity: .85;
  width: 4rem;
  height: 4rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  cursor: pointer;
  font-size: 1.8rem;
  transition: all .3s; }
  #gotop:hover {
    background-color: #6B7E9B; }

@media (max-width: 767px) {
  #gotop {
    right: 3rem;
    bottom: 3rem; } }

@media (max-width: 575px) {
  #gotop {
    right: 1rem;
    bottom: 2rem; } }

#body_404 #contenu {
  min-height: 60rem;
  display: flex;
  justify-content: center;
  align-items: center; }

#contact a {
  color: #6B7E9B; }
  #contact a:hover {
    color: #F2B52D; }

#contact .highlight {
  display: inline-block;
  font-weight: 700;
  margin-top: 1.5rem; }

@media (max-width: 991px) {
  #contact {
    margin-right: 2rem; } }

#realisations h1 {
  margin-top: 4rem;
  margin-left: 2rem; }

#references ul {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #F2B52D; }

.real_item {
  float: left;
  width: calc(100% / 4);
  height: 0;
  margin: 0;
  padding: calc(100% / 7) 0 0;
  position: relative;
  overflow: hidden; }
  .real_item a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

#references li .overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2em;
  transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  background-color: rgba(241, 120, 75, 0);
  z-index: 10; }
  #references li .overlay .title {
    font-size: 1.2em;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-family: inherit;
    margin-bottom: auto; }
  #references li .overlay .description {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.2em; }

#references li img {
  display: block;
  width: 100%;
  min-width: 100%;
  z-index: 9; }

#references li:hover .overlay {
  opacity: 1;
  visibility: visible; }

/*---------------*/
/***** Bubba *****/
/*---------------*/
.effect-bubba {
  background: #7e7e7e; }
  .effect-bubba img {
    opacity: 0.9;
    transform: scale(1.12);
    transition: opacity 0.35s, transform 0.35s, filter 0.35s; }
  .effect-bubba:hover img {
    opacity: 0.2;
    transform: scale(1);
    filter: blur(4px); }
  .effect-bubba .overlay::before,
  .effect-bubba .overlay::after {
    position: absolute;
    top: 15px;
    right: 20px;
    bottom: 20px;
    left: 15px;
    content: '';
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s; }
  .effect-bubba .overlay::before {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: scale(0, 1); }
  .effect-bubba .overlay::after {
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    transform: scale(1, 0); }
  .effect-bubba .title {
    position: absolute;
    width: calc(100% - 5em);
    bottom: 60%;
    left: 2.5em;
    margin-top: -30px;
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(0, 20px, 0); }
  .effect-bubba .description {
    position: absolute;
    width: calc(100% - 5em);
    top: 40%;
    left: 2.5em;
    margin-top: 10px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(0, 20px, 0); }
  .effect-bubba:hover .overlay::before,
  .effect-bubba:hover .overlay::after {
    opacity: 1;
    transform: scale(1); }
  .effect-bubba:hover .title,
  .effect-bubba:hover .description {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

@media (max-width: 1199px) {
  .real_item {
    width: calc(100% / 3);
    padding-top: calc(100% / 5); } }

@media (max-width: 767px) {
  .real_item {
    width: calc(100% / 2);
    padding-top: calc(100% / 4); }
  .effect-bubba .title {
    top: 40%;
    bottom: auto; } }

@media (max-width: 575px) {
  .real_item {
    width: calc(100% / 1);
    padding-top: calc(100% / 2); }
  #references li .overlay .description {
    display: none; }
  .effect-bubba .title {
    top: 50%; }
  .effect-bubba:hover .title {
    transform: translate3d(0, 50%, 0); } }

#reference {
  margin: 4rem 2rem; }

.current_article .realisation_content {
  display: flex;
  align-items: center;
  height: calc(100% - 5rem); }

#reference .btn_container {
  text-align: center;
  margin-top: 3rem; }

@media (max-width: 1199px) {
  .current_article .realisation_content {
    height: auto; } }

/* Commun a toutes les news */
.body_news h1 {
  margin-bottom: 2rem;
  font-weight: normal; }

.body_news .img_news a {
  position: relative;
  display: block; }

.body_news .img_news .date {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  color: #fff;
  background-color: #F2B52D;
  padding: .4rem .8rem;
  line-height: 16px;
  z-index: 1; }

/* affichage des tags */
.body_news ul.tags {
  list-style: none !important;
  display: flex;
  padding-left: 0; }

.body_news ul.tags li {
  display: inline-block;
  margin: 0 0.3rem 1rem;
  padding: 0.1rem 0.4rem;
  border: solid 1px var(--orange);
  font-size: 1.2rem; }

.body_news ul.tags li a {
  color: #fff;
  line-height: 20px; }

/* Specifique a la news current */
.current_news .current_news_text {
  display: flex;
  align-items: center;
  height: 100%; }

/*slider news avec vignettes et slider produits avec vignettes */
.current_news .main_image {
  padding-left: 0; }

.current_news .main_image img {
  height: 40rem;
  object-fit: cover;
  width: 100%;
  box-shadow: 6px 6px 6px 0 #cccccc;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#cccccc, Direction=135, Strength=6); }

.current_news .main_image_slider {
  display: none; }

.current_news .main_image_noslider {
  display: flex;
  height: 100%;
  align-items: center; }

.current_news .main_image_noslider li {
  list-style-type: none;
  width: 100%; }

.current_news .main_image_noslider .img_defaut_container {
  position: relative; }

.current_news .main_image_noslider img.img_defaut {
  object-fit: contain;
  background-color: #444; }

.current_news #lst_vignette {
  margin: .5rem -.2rem 1rem; }

.current_news #lst_vignette a {
  display: inline-block;
  margin: 0 .2rem; }

.current_news #lst_vignette a img {
  width: 6.3rem;
  height: 6.3rem;
  object-fit: cover; }

/* Spe aux listing */
.newslist article {
  display: flex; }

.newslist section,
.newslist footer {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.2rem; }

.newslist footer {
  text-align: center; }

.newslist .card_news {
  box-shadow: 6px 6px 6px 0 #cccccc;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#cccccc, Direction=135, Strength=6);
  background-color: var(--hard-dark);
  margin-bottom: 2rem; }

.newslist img {
  display: block;
  width: 100%;
  height: 36rem;
  object-fit: cover;
  background-color: #444; }

.newslist img.img_defaut {
  object-fit: contain;
  padding-left: 3rem;
  padding-right: 3rem; }

.newslist h3 a {
  display: block;
  height: 8.5rem;
  overflow: hidden;
  padding-top: 2rem;
  margin-bottom: 1.5rem;
  color: #6B7E9B; }

.newslist h3 a:hover {
  color: var(--dark-grey); }

.newslist .button {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.newslist .news_txt {
  height: 9rem;
  line-height: 1.4;
  overflow: hidden; }

/* Spe au listing avec 3 news sur la meme ligne */
.newslist .medium_list_news img {
  height: 25rem; }

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/
[class*="overlay-half"] {
  position: relative;
  z-index: 1; }

[class*="overlay-half"]::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: ""; }

.overlay-half-dark-v1::before {
  z-index: 2;
  background-color: rgba(0, 45, 89, 0.7); }

.overlay-half-dark-v2::before {
  background-image: linear-gradient(30deg, rgba(21, 27, 38, 0.5) 0%, transparent 100%);
  background-repeat: repeat-x; }

.baseline {
  position: relative;
  font-size: 5rem;
  color: #fff;
  font-weight: 900;
  letter-spacing: 0.1rem;
  line-height: 1;
  z-index: 100;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5rem;
  margin-top: 15vh;
  z-index: 1; }
  .baseline:after {
    content: '';
    display: block;
    width: 20%;
    height: 3px;
    background: #fff;
    margin: auto;
    margin-top: 35px; }

.bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  margin-bottom: 3rem; }

.wrapper_baseline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #6B7E9B;
  opacity: 0.9; }

.height-70vh {
  height: 70vh; }

/* Image header page type */
.img_header_pagetype {
  background: url("../img/img-pagetype.jpg") center center;
  background-size: cover;
  height: 25vh;
  width: 100%; }

#block-chiffres {
  font-weight: bold;
  margin-bottom: 15vh; }
  #block-chiffres p {
    margin: 0;
    padding: 0; }
  #block-chiffres .icon {
    font-size: 5rem;
    height: 50px; }
  #block-chiffres .block-chiffres-nombre {
    font-size: 4rem; }
  #block-chiffres .block-chiffres-legende p {
    font-size: 1.6rem;
    line-height: 1.8rem; }
  #block-chiffres .block-chiffres-content {
    width: 190px;
    height: 190px;
    background: #798AA2;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%; }

#block-intro .img-departement {
  max-width: 12rem; }

#jesuis {
  margin-bottom: 7rem;
  margin-top: 8rem;
  padding-bottom: 6rem; }
  #jesuis .h2 {
    position: relative;
    z-index: 1;
    color: #fff;
    margin-bottom: 180px;
    text-shadow: 1px 1px 2px #646464; }
    #jesuis .h2 span {
      font-size: 6rem; }
  #jesuis p {
    position: relative;
    line-height: 1.5;
    z-index: 1; }
  #jesuis .wrapper_jesuis {
    content: '';
    width: 100%;
    height: 80%;
    position: absolute;
    bottom: 0;
    background: #BEC7D3; }
  #jesuis .block-jesuis {
    -webkit-box-shadow: -5px 7px 7px -3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -5px 7px 7px -3px rgba(0, 0, 0, 0.1);
    box-shadow: -5px 7px 7px -3px rgba(0, 0, 0, 0.1);
    background: #fff; }
    #jesuis .block-jesuis .block-jesuis-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 300px;
      width: 100%; }
  #jesuis .block-jesuis:first-child .block-jesuis-img {
    /*background: url("../img/jesuis1.jpg") no-repeat bottom center #fff;*/
    background-size: cover; }
  #jesuis .block-jesuis:last-child .block-jesuis-img {
    /*background: url("../img/jesuis2.jpg") no-repeat bottom center #fff;*/
    background-size: cover; }
  #jesuis .block-jesuis-contact {
    position: absolute;
    display: block;
    top: 150px;
    right: 40px;
    width: 145px;
    height: 145px;
    border-radius: 50%;
    background: #6B7E9B;
    color: #fff;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    padding-top: 30px; }
    #jesuis .block-jesuis-contact .icon {
      display: block;
      font-size: 2.5rem;
      margin-bottom: 1rem; }
    #jesuis .block-jesuis-contact .border-contact {
      position: absolute;
      display: block;
      top: 5px;
      left: 5px;
      width: 135px;
      height: 135px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      border: solid 2px #fff; }

#actualites_block figure {
  position: relative;
  width: 100%;
  height: 38rem; }

#actualites_block img {
  object-fit: cover;
  height: 100%;
  width: 100%; }

#actualites_block .actu-promu {
  display: block; }
  #actualites_block .actu-promu a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 3; }
  #actualites_block .actu-promu .wrapper_dark {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 1; }
  #actualites_block .actu-promu .actu-promu-content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 90%;
    color: #fff;
    z-index: 3; }
  #actualites_block .actu-promu h4 {
    color: #fff; }

#block-partenaires {
  margin-bottom: 15rem;
  margin-top: 15rem; }
  #block-partenaires .slider_container {
    margin-top: 4rem; }
  #block-partenaires .partenaires_slider_container img {
    margin: auto; }

@media (max-width: 1199px) {
  #block-partenaires {
    margin-top: 5rem;
    margin-bottom: 5rem; } }

@media (max-width: 767px) {
  .baseline {
    font-size: 4rem; }
  #block-partenaires {
    margin-top: 0;
    margin-bottom: 2.5rem; } }

@media (max-width: 575px) {
  .baseline {
    font-size: 3rem; } }
